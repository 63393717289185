<template>
  <component :is="layout" style="min-height: 800px;">
    <slot />
  </component>
</template>

<script>

const defaultLayout = 'PagesLayout'

export default {
  name: "AppLayout",
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    }
  }
}
</script>

<style scoped>

</style>