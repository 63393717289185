import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from 'vuetify/lib/locale/de'
import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 500,
      sm: 800,
      md: 1200,
      lg: 1904,
    },
    scrollBarWidth: 24,
  },

  icons: {
    // iconfont: "mdi" || "md" || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    iconfont: "mdi" || "md" || "fa" || "fal",
  },
  lang: {
    locales: {de, fr, en},
    fallbackLocale: 'de',
  },
  theme: {
    themes: {
      light: {
        primary: {
          base: "#F17800",
          lighten3: "#FFBB78",
        },
        secondary: "#F4E9E3",
        tertiary: {
          base: "#DFDFDB",
          lighten3: "#EFEFEE",
        },
        accent: "#546BBE",
        bg: "#F8F4F1",
      },
    },
  },
});
