/*import plugins*/
import ENDPOINTS from '@/plugins/axios/endpoints';
import moment from 'moment';
import axios from 'axios';
import { i18n } from '../../../main';
import { DEFAULT_PAYMENT_METHOD } from '@/utils/constants';

let timer = null;
moment.locale('de');
moment.updateLocale('de', { week: { dow: 1, doy: 4 } });

const Config = {
  namespaced: true,

  state: {
    storeInfo: [],
    lang: 'de',
    images: {},
    layout: {},
    shop: {},
    productInfoSettings: {},
    openingTimes: [],
    now: null,
    enableOrderScheduling: false,
    maintenanceMode: false,
    offersDelivery: true,
    offersPickUp: true,
    isDelivery: false,
    preparationTime: 60,
    deliveryPreparationTime: 20,
    pickUpPreparationTime: 20,
    imprintText: '',
    paymentInfo: '',
    deliveryInfo: null,
    times: [],
    authDlg: 1,
    deliveryPayments: null,
    takeawayPayments: null,
    disableZipCodeCheck: false,
    geoloc: 'de',
    defaultLang: 'de',
  },

  getters: {
    disableZipCodeCheck: (state) => state.disableZipCodeCheck,
    
    geoLoc: (state) => state.geoloc,
    
    defaultLang: (state) => state.defaultLang,

    now: (state) => state.now,

    storeInfo: (state) => state.storeInfo,

    images: (state) => state.images,

    layout: (state) => state.layout,

    shop: (state) => state.shop,

    productInfoSettings: (state) => state.productInfoSettings,

    openingTimes: (state) => state.openingTimes,

    deliveryAreas: (state) => {
      return state.shop.deliveryArea?.map(area=>({
        name: state.disableZipCodeCheck ? (area.areaName || '') + (area.city?' - '+area.city:'') + (area.zip?' (' + area.zip + ')':'')  : area.zip ,
        ...area,
      }))
    },

    maintenanceMode: (state) => state.maintenanceMode,
    offersDelivery: (state) => state.offersDelivery,
    offersPickUp: (state) => state.offersPickUp,
    preparationTime: (state) =>
      Number(state.preparationTime) > 0 ? Number(state.preparationTime) : 20,
    deliveryPreparationTime: (state) => state.deliveryPreparationTime,
    pickUpPreparationTime: (state) => state.pickUpPreparationTime,
    imprintText: (state) => state.imprintText,
    paymentInfo: (state) => state.paymentInfo,
    isDelivery: (state) => state.isDelivery,
    deliveryInfo: (state) => state.deliveryInfo,
    enableOrderScheduling: (state) => state.enableOrderScheduling,
    alternativeText: (state, getters) => {
      if (state.maintenanceMode || !getters.isStillOpen || !getters.enableOrderScheduling) {
        return i18n.t('delivery.lang_restaurantCloseMsg');
      } else if (!state.offersDelivery && !state.offersPickUp) {
        return i18n.t('delivery.lang_pickupDeliveryUnavailableMsg');
      } else if (!state.offersPickUp) {
        return i18n.t('delivery.lang_pickupUnavailableMsg');
      } else if (!state.offersDelivery) {
        //return "delivery is currently unavailable for this store";
        return i18n.t('delivery.lang_deliveryUnavailableMsg');
      } else {
        return i18n.t('delivery.lang_howToRecieveOrder');
      }
    },

    authDlg: (state) => state.authDlg,

    times: (state, getters) => {
      let now = moment(getters.now, 'DD:MM:YYYY HH:mm');
      //SETTING MONDAY AS START OF THE WEEK
      let today = now.weekday();
      let preparationTime = getters.isDelivery
        ? getters.deliveryPreparationTime
        : getters.pickUpPreparationTime;
      let timeToDeliver =
        getters.isDelivery && getters.deliveryInfo
          ? getters.deliveryInfo.carDeliveryTime >
            getters.deliveryInfo.motorcycleDeliveryTime
            ? getters.deliveryInfo.carDeliveryTime
            : getters.deliveryInfo.motorcycleDeliveryTime
          : 0;
      let shift = 0;
      state.times = [];
      if (getters.openingTimes && getters.openingTimes.length > 0) {
        if (getters.openingTimes.length > today) {
          if (getters.openingTimes[today].open) {
            let start1 =
              getters.openingTimes[today]['shift1'].start !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift1'].start.substring(0, 5)
                : null;
            let end1 =
              getters.openingTimes[today]['shift1'].end !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift1'].end.substring(0, 5)
                : null;
            let start2 =
              getters.openingTimes[today]['shift2'].start !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift2'].start.substring(0, 5)
                : null;
            let end2 =
              getters.openingTimes[today]['shift2'].end !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift2'].end.substring(0, 5)
                : null;

            if (getters.openingTimes[today]['shift1'].open && start1 && end1) {
              if (
                now.isBetween(moment(start1, 'HH:mm'), moment(end1, 'HH:mm')) ||
                now.isBefore(moment(end1, 'HH:mm'))
              ) {
                start1 = moment(start1, 'HH:mm');
                end1 = moment(end1, 'HH:mm');
                let time = moment(start1);
                if (
                  now.isBetween(moment(start1, 'HH:mm'), moment(end1, 'HH:mm'))
                ) {
                  time = moment(now, 'HH:mm');
                  time.add(preparationTime + timeToDeliver, 'minutes');
                  shift = 1;
                } else {
                  time.add(preparationTime + timeToDeliver, 'minutes');
                }

                let cntr = 0;
                while (time.isBefore(end1)) {
                  if (time.isAfter(now)) {
                    state.times.push({ name: time.format('HH:mm'), value: time.format('HH:mm') });
                  }

                  if (cntr > 0) {
                    time = time.add(15, 'minute');
                  }
                  cntr++;
                }
              }
            }

            if (getters.openingTimes[today]['shift2'].open && start2 && end2) {
              if (
                now.isBetween(moment(start2, 'HH:mm'), moment(end2, 'HH:mm')) ||
                now.isBefore(moment(end2, 'HH:mm'))
              ) {
                start2 = moment(start2, 'HH:mm');
                end2 = moment(end2, 'HH:mm');
                let time = moment(start2);

                if (
                  now.isBetween(moment(start2, 'HH:mm'), moment(end2, 'HH:mm'))
                ) {
                  time = moment(now, 'HH:mm');
                  time.add(preparationTime + timeToDeliver, 'm');
                  shift = 2;
                } else {
                  time.add(preparationTime + timeToDeliver, 'm');
                }

                let cntr = 0;
                while (time.isBefore(end2)) {
                  if (time.isAfter(now)) {
                    state.times.push({ name: time.format('HH:mm'), value: time.format('HH:mm') });
                  }
                  if (cntr > 0) {
                    time = time.add(15, 'minute');
                  }
                  cntr++;
                }
              }
            }

            if (shift !== 0) {
              state.times[0] = {
                name: i18n.t('delivery.lang_asap'),
                value: 'asap',

              };
            }
          }
        }
      }
      return state.times;
    },

    getScheduleDayTimes: (state, getters) => day =>{
      let now = moment(getters.now, 'DD:MM:YYYY HH:mm');
      //SETTING MONDAY AS START OF THE WEEK
      let preparationTime = getters.isDelivery
          ? getters.deliveryPreparationTime
          : getters.pickUpPreparationTime;
      let timeToDeliver =
          getters.isDelivery && getters.deliveryInfo
              ? getters.deliveryInfo.carDeliveryTime >
              getters.deliveryInfo.motorcycleDeliveryTime
                  ? getters.deliveryInfo.carDeliveryTime
                  : getters.deliveryInfo.motorcycleDeliveryTime
              : 0;
      let tmpTimes = [];
      if (getters.openingTimes && getters.openingTimes.length > 0) {
        if (getters.openingTimes.length > day) {
          if (getters.openingTimes[day].open) {
            let start1 =
                getters.openingTimes[day]['shift1'].start !== '00:00:00.000000'
                    ? getters.openingTimes[day]['shift1'].start.substring(0, 5)
                    : null;
            let end1 =
                getters.openingTimes[day]['shift1'].end !== '00:00:00.000000'
                    ? getters.openingTimes[day]['shift1'].end.substring(0, 5)
                    : null;
            let start2 =
                getters.openingTimes[day]['shift2'].start !== '00:00:00.000000'
                    ? getters.openingTimes[day]['shift2'].start.substring(0, 5)
                    : null;
            let end2 =
                getters.openingTimes[day]['shift2'].end !== '00:00:00.000000'
                    ? getters.openingTimes[day]['shift2'].end.substring(0, 5)
                    : null;

            if (getters.openingTimes[day]['shift1'].open && start1 && end1) {
              start1 = moment(start1, 'HH:mm');
              end1 = moment(end1, 'HH:mm');
              let time = moment(start1);
              time.add(preparationTime + timeToDeliver, 'minutes');
              let cntr = 0;
              while (time.isBefore(end1)) {
                tmpTimes.push({ name: time.format('HH:mm'), value: time.format('HH:mm') });
                time = time.add(15, 'minute');
                cntr++;
              }
            }

            if (getters.openingTimes[day]['shift2'].open && start2 && end2) {
                start2 = moment(start2, 'HH:mm');
                end2 = moment(end2, 'HH:mm');
                let time = moment(start2);
                time.add(preparationTime + timeToDeliver, 'm');
                let cntr = 0;
                while (time.isBefore(end2)) {
                    tmpTimes.push({ name: time.format('HH:mm'), value: time.format('HH:mm') });
                    time = time.add(15, 'minute');
                  cntr++;
                }
              }
          }
        }
      }
      return tmpTimes;
    },

    isStillOpen: (state, getters) => {
      let now = moment(getters.now, 'DD:MM:YYYY HH:mm');
      //SETTING MONDAY AS START OF THE WEEK
      let today = now.weekday();
      if (getters.openingTimes && getters.openingTimes.length > 0) {
        if (getters.openingTimes.length > today) {
          if (getters.openingTimes[today].open) {
            let start1 =
              getters.openingTimes[today]['shift1'].start !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift1'].start.substring(0, 5)
                : null;
            let end1 =
              getters.openingTimes[today]['shift1'].end !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift1'].end.substring(0, 5)
                : null;
            let start2 =
              getters.openingTimes[today]['shift2'].start !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift2'].start.substring(0, 5)
                : null;
            let end2 =
              getters.openingTimes[today]['shift2'].end !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift2'].end.substring(0, 5)
                : null;
            if (getters.openingTimes[today]['shift1'].open && start1 && end1) {
              if (
                now.isBetween(moment(start1, 'HH:mm'), moment(end1, 'HH:mm')) ||
                now.isBefore(moment(end1, 'HH:mm'))
              ) {
                return true;
              }
            }
            if (getters.openingTimes[today]['shift2'].open && start2 && end2) {
              if (
                now.isBetween(moment(start2, 'HH:mm'), moment(end2, 'HH:mm')) ||
                now.isBefore(moment(end2, 'HH:mm'))
              ) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    // METHOD TO CHECK IF THE STORE IS OPEN OR NOT THAT/IF WE NOW IS BETWEEN THE OPENING HOURS
    isOpen: (state, getters) => {
      let now = moment(getters.now, 'DD:MM:YYYY HH:mm');
      //SETTING MONDAY AS START OF THE WEEK
      let today = now.weekday();
      if (getters.openingTimes && getters.openingTimes.length > 0) {
        if (getters.openingTimes.length > today) {
          if (getters.openingTimes[today].open) {
            let start1 =
              getters.openingTimes[today]['shift1'].start !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift1'].start.substring(0, 5)
                : null;
            let end1 =
              getters.openingTimes[today]['shift1'].end !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift1'].end.substring(0, 5)
                : null;
            let start2 =
              getters.openingTimes[today]['shift2'].start !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift2'].start.substring(0, 5)
                : null;
            let end2 =
              getters.openingTimes[today]['shift2'].end !== '00:00:00.000000'
                ? getters.openingTimes[today]['shift2'].end.substring(0, 5)
                : null;
            if (getters.openingTimes[today]['shift1'].open && start1 && end1) {
              start1 = moment(
                now.format('DD:MM:YYYY ') + start1,
                'DD:MM:YYYY HH:mm'
              );
              end1 = moment(
                now.format('DD:MM:YYYY ') + end1,
                'DD:MM:YYYY HH:mm'
              );

              if (now.isBetween(start1, end1)) {
                return true;
              }
            }
            if (getters.openingTimes[today]['shift2'].open && start2 && end2) {
              start2 = moment(
                now.format('DD:MM:YYYY ') + start2,
                'DD:MM:YYYY HH:mm'
              );
              end2 = moment(
                now.format('DD:MM:YYYY ') + end2,
                'DD:MM:YYYY HH:mm'
              );
              if (now.isBetween(start2, end2)) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },

    deliveryPayments: (state) => state.deliveryPayments,
    takeawayPayments: (state) => state.takeawayPayments,
    dynamicContent: (state) => state.dynamicContent,
    lang: (state) => state.lang,
  },

  mutations: {
    setLanguage(state, val) {
      state.lang = val;
    },
    setStoreInfo: (state, val) => {
      let defaultLayout = {
        logo: { placement: 'center' },
        navbar: {
          backgroundColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textSize: null,
        },
        body: { backgroundColor: null, textColor: null, textSize: null },
        footer: {
          backgroundColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textSize: null,
        },
        buttons: {
          backgroundColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textSize: null,
        },
        dialogs: {
          backgroundColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textSize: null,
        },
        cart: {
          backgroundColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textSize: null,
          buttonsColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textButtonsColor: {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: { h: 0, s: 1, l: 0.5, a: 1 },
            hsva: { h: 0, s: 1, v: 1, a: 1 },
            hue: 0,
            rgba: { r: 255, g: 0, b: 0, a: 1 },
          },
          textButtonsSize: null,
        },
      };
      let defaultPayment = [DEFAULT_PAYMENT_METHOD];

      state.storeInfo = val;

      state.images = val.images;
      state.layout =
        val.layout && Object.keys(val.layout).length > 0
          ? val.layout
          : defaultLayout;
      state.shop = val.shop;
      state.openingTimes = val.openingTimes;
      state.productInfoSettings = {
        hideProductInfo: val.hideProductInfo,
        showStandardProductInfo: val.showStandardProductInfo,
        showProductInfo: val.showProductInfo,
        showAllergens: val.showAllergens,
        standardProductInfo: val.standardProductInfo,
      };
      state.maintenanceMode = val.maintenanceMode;
      state.offersDelivery = val.offersDelivery;
      state.offersPickUp = val.offersPickUp;
      state.preparationTime = Number(val.preparationTime)
        ? Number(val.preparationTime)
        : 20;
      state.deliveryPreparationTime = Number(val.lb_deliveryPreparationTime)
        ? Number(val.lb_deliveryPreparationTime)
        : 20;
      state.pickUpPreparationTime = Number(val.lb_pickUpPreparationTime)
        ? Number(val.lb_pickUpPreparationTime)
        : 20;
      state.dynamicContent = val.dynamicContent;
      state.imprintText = val.imprintText;
      state.paymentInfo = val.paymentInfo;
      state.enableOrderScheduling = val.lb_enableOrderScheduling;

      state.deliveryPayments =
        val.Delivery.paymentMethods && val.Delivery.paymentMethods?.length > 0
          ? val.Delivery.paymentMethods
          : defaultPayment;
      state.takeawayPayments =
        val.takeAway.paymentMethods && val.takeAway.paymentMethods?.length > 0
          ? val.takeAway.paymentMethods
          : defaultPayment;

      state.disableZipCodeCheck = val.lb_disableZipCodeCheck || false
      state.geoloc = val.geoloc_systemcountry || 'de'
      state.defaultLang = val.lb_defaultLang || 'de'
      
    },

    changeMaintenanceMode: (state, val) => {
      state.maintenanceMode = val;
    },

    setDeliveryInfo(state, payload) {
      state.deliveryInfo = payload;
    },

    setAuthDlg: (state, val) => {
      state.authDlg = val;
    },

    setDelivery: (state, val) => {
      state.isDelivery = val;
    },
    setTime: (state, val) => {
      state.now = val;
    },
    startSync: (state) => {
      timer = window.setInterval(() => {
        window.store.commit(
          'Config/setTime',
          moment(state.now, 'DD:MM:YYYY HH:mm')
            .add(1, 'minutes')
            .format('DD:MM:YYYY HH:mm')
        );
        window.store.getters['Config/times'];
        window.store.getters['Config/isStillOpen'];
        window.store.getters['Config/isOpen'];
      }, 60000);
    },
    stopSync() {
      clearInterval(timer);
    },
  },

  actions: {
    async loadStoreInfo({ commit }) {
      commit('stopSync');
      window.store.commit('Loader/changeStatus', true); //start global loading
      return new Promise((resolve, reject) => {
        axios
          .get(ENDPOINTS.DELIVERY.STOREINFO.GET_ALL)
          .then((response) => {
            if (
              response.status >= 200 &&
              response.status < 300 &&
              response.data !== '' &&
              response.data !== null &&
              typeof response.data !== 'undefined'
            ) {
              commit('setTime', response.data.serverTime);
              commit('setStoreInfo', response.data);
              commit('startSync');

              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            window.store.commit('Loader/changeStatus', false); //start global loading
          });
      });
    },
  },
};

export default Config;
