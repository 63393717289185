//import {store} from "../store";
import multiguard from "vue-router-multiguard";
import config from "@/middlewares/config/index";

const routes = [
  {
    path: "/",
    name: "RestaurantMenu",
    component: () => import("@/views/Menu/index.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/info",
    name: "Info",
    component: () => import("@/views/Info/index.vue"),
    beforeEnter: multiguard([config]),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/checkout-order",
    name: "CheckoutOrder",
    component: () => import("@/views/Checkout/index.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/order",
    name: "TrackingOrder",
    beforeEnter: multiguard([config]),
    component: () => import("@/views/TrackingOrder/index.vue"),
    meta: {
      layout: "DefaultLayout"
    }
    /*beforeEnter: (to, from, next) => {
      if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
      else next();
    },*/
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/Account/index.vue"),
    meta: {
      layout: "DefaultLayout"
    }
    /*beforeEnter: (to, from, next) => {
      if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
      else next();
    },*/
  },
  {
    path: "/agb",
    name: "AGB",
    component: () => import("@/views/AGB.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () => import("@/views/Datenschutz.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("@/views/Impressum.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFound/index.vue"),
    meta: {
      layout: "DefaultLayout"
    }
  },
  {
    path: "*",
    redirect: "/404"
  }
];

export default routes;
