import Vue from "vue";
import VueRouter from "vue-router";
import { isBreakfast } from "../helpers/breakfast";
//import {store} from "../store";

Vue.use(VueRouter);

const routes = require("@/router/routes").default;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
  routes
});

router.beforeEach(async (to, from, next) => {
  // always keep breakfast query to the next route if we're in breakfast order mode

  /*  let isBreakfastInBothFromAndTo,
      isBreakfastInOneOfFromAndTo = false;

    isBreakfastInBothFromAndTo =
      Object.prototype.hasOwnProperty.call(to.query, "isBreakfast") &&
      Object.prototype.hasOwnProperty.call(from.query, "isBreakfast");

    if (!isBreakfastInOneOfFromAndTo)
      isBreakfastInOneOfFromAndTo =
        Object.prototype.hasOwnProperty.call(to.query, "isBreakfast") ||
        Object.prototype.hasOwnProperty.call(from.query, "isBreakfast");*/

  const currentPathName = window.location.pathname;

  if (
    to.path !== currentPathName &&
    isBreakfast() &&
    !Object.prototype.hasOwnProperty.call(to.query, "isBreakfast")
    /*to.path !== currentPathName &&
    (isBreakfastInBothFromAndTo || isBreakfastInOneOfFromAndTo)*/
  ) {
    let fromQuery = { ...from.query };
    let toQuery = { ...to.query };

    //avoid duplicate orderUUID
    if (
      currentPathName === "/order" &&
      Object.prototype.hasOwnProperty.call(fromQuery, "orderNumber")
    ) {
      delete fromQuery.orderNumber;
    }

    await router.push({
      ...to,
      query: {
        ...fromQuery,
        ...toQuery
      }
    });
    return;
  }

  next();
});

router.onError(err => {
  console.log("hadi error d router ==> ", err);
});

//TODO add middleware to check if this order is already finished or not
export default router;
