<template>
  <v-app class="overflow-y-hidden" style="min-height: 800px;" >
    <v-overlay color="white" opacity="0.9" z-index="4000" :value="loader">
      <v-progress-circular color="primary" indeterminate size="128"/>
    </v-overlay>
    <AppLayout>
      <router-view/>
    </AppLayout>
    <!-- Cookie -->
    <cookie-law theme="dark-lime" :buttonText="$t('delivery.lang_accept')">
      <div slot="message">
        {{$t('delivery.lang_cookiesMSG')}}
        <router-link to="/datenschutz">{{$t('generic.lang_dataProtection')}}</router-link>
      </div>
    </cookie-law>
    <PWACheck></PWACheck>
    <DeliveryAreaDialog v-model="showDialog" :dialog="showDialog"/>
  </v-app>
</template>

<script>
//import plugins
import {createNamespacedHelpers, mapState} from "vuex"
import AppLayout from '@/layouts/AppLayout.vue'
import CookieLaw from 'vue-cookie-law'
import PWACheck from "./components/PWACheck";
import DeliveryAreaDialog from "./components/Card/DeliveryAreaDialog";
export default {
  components: {DeliveryAreaDialog, PWACheck, AppLayout,CookieLaw},

  data() {
    return {
      showDialog:false
    };
  },
  methods:{
    checkAuth(){
      if(this.$store.getters["auth/isLoggedIn"]){
        this.axios.defaults.headers.common['Authorization'] = "Bearer "+this.$store.getters["auth/userToken"];
      }
    }
  },

  computed: {
    ...mapState([
      'auth',
    ]),
    ...createNamespacedHelpers("Loader").mapGetters([
      "loader"
    ]),
    ...createNamespacedHelpers("Config").mapGetters([
      "lang",
      "defaultLang",
    ]),
  },
  beforeDestroy() {
    this.$root.$off('toggleDeliveryAreaDialog')
  },
  mounted() {
    this.checkAuth();
    this.$i18n.locale = this.lang;
    this.$root.$on('toggleDeliveryAreaDialog',(val)=>{
      this.showDialog=val;
    })
  }
};
</script>
