<template>
  <v-dialog v-model="visible" @click:outside="visible=false" max-width="500px" overlay-opacity="0.2">
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        {{ disableZipCodeCheck?$t('delivery.lang_pleaseSelectYourDeliveryArea'):$t('delivery.lang_pleaseSelectZip') }}
      </v-toolbar-title>
      <v-spacer/>
      <v-btn icon @click="visible=false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card
        tile
        elevation="0"
        width="100%"
        class="px-4 pt-7 mx-auto d-inline-block"
    >
      <div>

        <!-- Are you sure you want to delete this album? -->

        <v-autocomplete
            ref="zipSelected"
            :items="deliveryAreas"
            item-text="name" :label="disableZipCodeCheck?$t('delivery.lang_pleaseSelectYourDeliveryArea'):$t('delivery.lang_pleaseSelectZip')"
            :rules="rules" return-object
            hide-selected @change="setDeliverArea($event)" @click:clear="setDeliveryInfo(null)"
            outlined clearable
            auto-select-first
            persistent-hint
            :value="deliveryInfo"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('delivery.lang_noResults') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <v-card-actions
          class="d-flex justify-content-end"
      >
        <v-btn
            @click="visible=false"
            color="accent"
            dark
            class="px-2  text-capitalize text-right"
        >
          {{ $t('generic.lang_next') }}
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {createNamespacedHelpers} from "vuex";

export default {
  name: "DeliveryAreaDialog",
  props:{
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data(){
    return {
      rules: [(v) => !!v || this.$t('delivery.lang_required')],
    }
  },

  computed:{
    ...createNamespacedHelpers("Order").mapGetters([
      "cart",
      "isCartEmpty",
      "cartTotalSum",
      "totalSum",
    ]),

    ...createNamespacedHelpers("auth").mapGetters(["isLoggedIn"]),

    ...createNamespacedHelpers("Config").mapGetters([
      "maintenanceMode",
      "offersDelivery",
      "offersPickUp",
      "isDelivery",
      "shop",
      "deliveryInfo",
      "deliveryAreas",
      "isStillOpen",
      "disableZipCodeCheck",
    ]),
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods:{
    ...createNamespacedHelpers("Config").mapMutations([
      "setDelivery"
    ]),
    ...createNamespacedHelpers("Order").mapMutations([
      "setItemPrices"
    ]),
    ...createNamespacedHelpers("Config").mapMutations([
      "setDeliveryInfo",
    ]),
    setDeliverArea(deliveryArea){
      this.setDeliveryInfo(deliveryArea)
    },
  }
}
</script>

<style scoped>

</style>