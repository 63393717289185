import Vue from "vue";

import VueCurrencyFilter from "vue-currency-filter";

Vue.use(VueCurrencyFilter, {
  // default name 'currency'
  symbol: "€",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "",
  symbolSpacing: true
});

const getCurrencyData = isoCode => {
  const currency = {
    currency: isoCode === "ma" ? "MAD" : "EUR"
  };

  const formatter = new Intl.NumberFormat(isoCode, {
    style: "currency",
    currency: currency.currency
  });

  formatter.formatToParts(120000.99).forEach(entry => {
    switch (entry.type) {
      case "currency":
        currency.symbol = isoCode === "ma" ? "DH" : entry.value;
        break;
      case "group":
        currency.thousandsSeparator = entry.value;
        break;
      case "decimal":
        currency.fractionSeparator = entry.value;
        break;
      case "fraction":
        currency.fractionCount = entry.value.length;
        break;
    }
  });

  return currency;
};

export const updateCurrencyFilter = (vm, isoCode) => {
  const currencyData = getCurrencyData(isoCode);
  vm.$currency.setConfig({
    symbol: currencyData.symbol,
    thousandsSeparator: currencyData.thousandsSeparator,
    fractionCount: currencyData.fractionCount,
    fractionSeparator: currencyData.fractionSeparator,
    symbolPosition: "back",
    symbolSpacing: true,
    avoidEmptyDecimals: "00"
  });

  vm.$forceUpdate();
};
