const ENDPOINTS = {
  DELIVERY: {
    ALLRESTAURANTS: '/ActiveRestaurants',
    STOREINFO: {
      GET_ALL: '/localbee/v2/storeinformations',
    },
    ITEMGROUPS: {
      GET_ALL: '/localbee/v2/itemgroups',
    },
    ORDERS: {
      GET: '/localbee/v2/orders',
      GET_ORDER: '/localbee/v2/order',
      CREATE: '/localbee/v2/orders/',
      MERGED: '/localbee/v2/mergeorders',
    },
    REGISTER: {
      POST: '/auth/register',
    },
    LOGIN: {
      POST: '/auth/login',
    },
    EMAILVALIDATION: '/localbee/v2/auth/emailverify/',
    RESETPASSWORD: '/localbee/v2/auth/password/',
  },
  ACCOUNT: {
    LIKE: {
      TRIGGER: '/localbee/v2/account/Like',
    },
    INFO: {
      GET: 'localbee/v2/account/userinfo',
      UPDATE: 'localbee/v2/account/updateuserdata',
      CHANGEPASSWORD: 'localbee/v2/account/ChangePassword',
      UPDATEIMAGE: 'localbee/v2/account/uploadImage',
    },
    ORDER: {
      HISTORY: 'localbee/v2/account/userorders',
      FEEDBACK: {
        GET: 'localbee/v2/getfeedback',
        PUT: 'localbee/v2/createfeedback',
      },
    },
    FAV: 'localbee/v2/account/FavoriteRestaurants',
    RESETPASSWORD: 'localbee/v2/auth/password',
  },
  ERP: {
    MEALTYPES: {
      GET_ALL: 'meal-types',
    },
    ALLERGENS: {
      GET_ALL: 'localbee/v2/GetAllergens',
    },
    GIFTCARDS: {
      GET: (id) => `localbee/v2/giftcards/${id}`,
    },
  },
};

window.ENDPOINTS = ENDPOINTS;
export default ENDPOINTS;
