import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/axios/index";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/currency";
// --- Internationalisation ---
import VueI18n from "vue-i18n";
import messages from "./i18n";
import AppLayout from "@/layouts/AppLayout.vue";
import moment from "moment";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
library.add(fab);
library.add(fal);
library.add(far);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

// --- VUE  OSM MAPS ---
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader

Vue.use(VueLayers);

moment.locale("de");
moment.updateLocale("de", { week: { dow: 1, doy: 4 } });

Vue.component("AppLayout", AppLayout);

// vue sweet alert 2

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import FlagIcon from "vue-flag-icon";

// Global CSS
import "@/assets/global.css";

Vue.use(FlagIcon);

Vue.use(VueI18n);

export const i18n = new VueI18n({
	locale: "de",
	fallbackLocale: "de",
	silentTranslationWarn: true, //DISABLE WARNINGS
	messages,
});

const swalOptions = {
	reverseButtons: true,
	cancelButtonText: i18n.t("generic.lang_cancel"),
	confirmButtonText: "OK",
};

Vue.use(VueSweetalert2, swalOptions);

Vue.config.productionTip = false;

// --- PWA ---
import "./registerServiceWorker";

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
	i18n,
}).$mount("#app");
