import Vue from "vue";
import Vuex from "vuex";

/*import vuex modules*/
import Config from "@/store/modules/Config/";
import ItemGroups from "@/store/modules/ItemGroups/";
import Filter from "@/store/modules/Filter/";
import Order from "@/store/modules/Order/";
import Checkout from "@/store/modules/Checkout/";
import auth from "@/store/modules/Auth/index";
import Loader from "@/store/modules/Loader/index";

Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";


const Store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    Config,
    ItemGroups,
    Filter,
    Order,
    Checkout,
    auth,
    Loader
  },
});

window.store = Store;
export default Store;
