<template>
    <v-snackbar color="accent" v-model="showSnackbar" :timeout="-1" top center>
        <v-card-text>
          <b>{{$t('delivery.lang_newVersionUpdate')}}</b>
        </v-card-text>
        <v-spacer />
        <v-btn dark text color="primary" @click.stop="refreshApp">{{$t('delivery.lang_account_update')}}</v-btn>
      <template v-slot:action>
        <v-btn dark icon @click="showSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "PWACheck",

        data() {
            return {
                showSnackbar: false,
                refreshing: false,
                registration: null,
            }
        },

        created() {
            // Listen for swUpdated event and display refresh snackbar as required.
            document.addEventListener('swUpdated', this.showRefreshUI, { once: true });

            // Refresh all open app tabs when a new service worker is installed.
            if(navigator.serviceWorker){
                navigator.serviceWorker.addEventListener('controllerchange', () => {
                    if (this.refreshing) return;
                    
                    this.refreshing = true;
                    window.location.reload();
                }); 
            }
        },

        methods: {
            showRefreshUI(e) {
                this.registration = e.detail;
                this.showSnackbar = true;
            },
            refreshApp() {
                this.showSnackbar = false;

                // Protect against missing registration.waiting.
                if (!this.registration || !this.registration.waiting) { return; }
                this.registration.waiting.postMessage('skipWaiting');
            },
        },
    }
</script>