/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";
import axios from "axios";
import { isBreakfast } from "../../../helpers/breakfast";

const Checkout = {
  namespaced: true,

  state: {
    paymentType: 1,
    items: []
  },

  getters: {},

  mutations: {
    setItemsOrdered: (state, val) => {
      state.items = [];
      var garn = [];

      val.forEach(el => {
        el.garnish.forEach(element => {
          garn.push({
            id: element.id,
            amount: element.amount
          });
        });

        state.items.push({
          id: el.id,
          amount: el.amount,
          freetext: el.freetext,
          mealsize: el.mealsize.id || 0,
          garnish: garn
        });
      });
    },

    setIsDelivery: (state, val) => {
      state.isDelivery = val;
      localStorage.setItem("isDelivery", val);
    },
    setPaymentType: (state, val) => {
      state.paymentType = val;
      localStorage.setItem("paymentType", val);
    },
    setFreeText: (state, val) => {
      state.freetext = val;
      localStorage.setItem("freetext", val);
    },
    setDeliveryDate: (state, val) => {
      state.deliveryDate = val;
      localStorage.setItem("deliveryDate", val);
    },
    setDeliveryTime: (state, val) => {
      state.deliveryTime = val;
      localStorage.setItem("deliveryTime", val);
    },
    setCustomerCreateAccount: (state, val) => {
      state.customerCreateAccount = val;
      localStorage.setItem("customerCreateAccount", val);
    },

    setSalutation: (state, val) => {
      state.salutation = val;
      localStorage.setItem("salutation", val);
    },
    setFirstName: (state, val) => {
      state.firstname = val;
      localStorage.setItem("firstname", val);
    },
    setLastName: (state, val) => {
      state.lastname = val;
      localStorage.setItem("lastname", val);
    },
    setStreet: (state, val) => {
      state.street = val;
      localStorage.setItem("street", val);
    },
    setStreetNumber: (state, val) => {
      state.streetNumber = val;
      localStorage.setItem("streetNumber", val);
    },
    setZip: (state, val) => {
      state.zip = val;
      localStorage.setItem("zip", val);
    },
    setCity: (state, val) => {
      state.city = val;
      localStorage.setItem("city", val);
    },
    setFloor: (state, val) => {
      state.floor = val;
      localStorage.setItem("floor", val);
    },
    setMobile: (state, val) => {
      state.mobile = val;
      localStorage.setItem("mobile", val);
    },
    setEmail: (state, val) => {
      state.email = val;
      localStorage.setItem("email", val);
    },
    setCompany: (state, val) => {
      state.company = val;
      localStorage.setItem("company", val);
    },
    setPassword: (state, val) => {
      state.password = val;
      localStorage.setItem("password", val);
    }
  },

  actions: {
    async createOrder({ commit, state }) {
      commit("Loader/changeStatus", true, { root: true }); //start global loading

      await axios
        .post(ENDPOINTS.DELIVERY.ORDERS.CREATE, {
          isDelivery: state.isDelivery,
          paymentType: state.paymentType,
          freetext: state.freetext,

          deliveryDate: "2021-06-21",
          deliveryTime: state.deliveryTime,
          customerCreateAccount: state.customerCreateAccount,
          customer: {
            salutation: state.salutation,
            firstname: state.firstname,
            lastname: state.lastname,
            street: state.street,
            streetNumber: state.streetNumber,
            zip: state.zip,
            city: state.city,
            floor: state.floor,
            mobile: state.mobile,
            email: state.email,
            company: state.company,
            password: state.password
          },
          items: state.items,
          isBreakfast: isBreakfast()
        })
        .then(response => {
          if (
            response.status >= 200 &&
            response.status < 300 &&
            response.data !== "" &&
            response.data.length !== 0 &&
            response.data !== null &&
            typeof response.data !== "undefined"
          ) {
            //commit("setStoreInfo", response.data);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          commit("Loader/changeStatus", false, { root: true }); //start global loading
        });
    }
  }
};

export default Checkout;
