import Vue from "vue";
import { firstOrderDate, isBreakfast } from "../../../helpers/breakfast";

import moment from "moment";

export default {
  namespaced: true,

  state: {
    items: [],

    cartTotalSum: 0,
    totalSum: 0,

    current: 0,

    selectedItem: [],

    isAlreadyDuplicatedForNextDays: false,

    voucher: null
  },

  getters: {
    voucher: state => state.voucher,
    is_AlreadyDuplicatedForNextDays: state =>
      state.isAlreadyDuplicatedForNextDays,
    getDeliveryInfo: state => {
      return state.deliveryInfo;
    },
    isCurrent: state => id => {
      if (id === state.current) {
        return true;
      } else return false;
    },
    selectedItem: state => state.selectedItem,

    cart(state) {
      if (Array.isArray(state.items)) {
        return state.items;
      }
      return [];
    },

    isCartEmpty(state) {
      if (
        !Array.isArray(state.items) ||
        (Array.isArray(state.items) && state.items.length === 0)
      ) {
        return true;
      } else {
        return false;
      }
    },

    //total price of item
    itemTotalPrice: state => data => {
      let itemIndex = state.items.indexOf(data);

      const item = state.items[itemIndex];
      let total = 0;
      if (item) {
        //item extras total price
        var extrasTotalPrice = 0;

        extrasTotalPrice += item.garnish.reduce(
          (extras_acc, garnish) =>
            extras_acc + parseFloat(garnish.sell_price) * garnish.amount,
          0
        );

        total = (parseFloat(item.sellPrice) + extrasTotalPrice) * item.amount;
        total -= total * (item.discount / 100);
      }
      return total;
    },

    cartTotalSum(state) {
      let total = 0;

      state.items.forEach(item => {
        for (let x = 1; x <= item.amount; x++) {
          let itemPrice = 0;

          //CHECK MEALSIZE
          if (item.mealsize) {
            itemPrice = item.mealsize.sellPrice;
            //CHECK DISCOUNT
            /*if (item.discountPriceBrutto !== 0) {
              itemPrice = item.discountPriceBrutto;
            }*/
          } else {
            itemPrice = item.sellPrice;

            //CHECK DISCOUNT
            /*if (item.mealsize.discountPriceBrutto !== 0) {
              itemPrice = item.mealsize.discountPriceBrutto;
            }*/
          }

          //GARNISH
          if (item.garnish) {
            item.garnish.forEach(garnishItem => {
              let garnishItemPrice = 0;

              garnishItemPrice = garnishItem.sell_price * garnishItem.amount;

              itemPrice += garnishItemPrice;
            });
          }

          // apply discount;
          itemPrice -= itemPrice * (item.discount / 100);
          total += itemPrice;
        }
      });

      return total;
    },
    cartTotalSumWithVoucher(state, getters) {
      let total = getters.cartTotalSum;
      if (state.voucher && parseFloat(state.voucher?.balance) > 0)
        total -= parseFloat(state.voucher?.balance);

      return total;
    },
    totalSumWithVoucher(state, getters) {
      let total = getters.totalSum;

      if (state.voucher && parseFloat(state.voucher?.balance) > 0)
        total -= parseFloat(state.voucher?.balance);

      return total;
    },

    totalSum(state) {
      let total = 0;

      state.items.forEach(item => {
        for (let x = 1; x <= item.amount; x++) {
          let itemPrice = 0;

          //itemPrice = item.sellPrice;

          //CHECK MEALSIZE

          if (item.mealsize) {
            itemPrice = item.mealsize.sellPrice;

            //CHECK DISCOUNT
            /*if (item.discountPriceBrutto !== 0) {
              itemPrice = item.discountPriceBrutto;
            }*/
          } else {
            itemPrice = item.sellPrice;

            //CHECK DISCOUNT
            /*if (item.mealsize.discountPriceBrutto !== 0) {
              itemPrice = item.mealsize.discountPriceBrutto;
            }*/
          }

          //GARNISH
          if (item.garnish) {
            item.garnish.forEach(garnishItem => {
              let garnishItemPrice = 0;

              garnishItemPrice = garnishItem.sell_price * garnishItem.amount;

              //DISCOUNT
              /*if (garnishItem.discountPriceBrutto !== 0) {
                garnishItemPrice =
                  garnishItem.discountPriceBrutto * garnishItem.amount;
              }*/

              itemPrice += garnishItemPrice;
            });
          }
          // apply discount;
          itemPrice -= itemPrice * (item.discount / 100);
          total += itemPrice;
        }
      });

      if (window.store.getters["Config/isDelivery"]) {
        if (window.store.getters["Config/deliveryInfo"]) {
          total += window.store.getters["Config/deliveryInfo"].deliveryCosts;
        }
      }

      return total;
    }
  },

  mutations: {
    setVoucher(state, payload) {
      state.voucher = payload;
    },
    setOrderAsAlreadyDuplicated(state, payload) {
      state.isAlreadyDuplicatedForNextDays = payload;
    },
    replaceCart(state, payload) {
      state.items = payload;
    },
    setToCurrent(state, val) {
      state.current = val;
    },

    setSelectedItem(state, val) {
      state.selectedItem = val;
    },

    bookItem(state, data) {
      //in breakfast mode we've to set booking date if it provided else we'll take the start date as item date
      if (
        isBreakfast() &&
        !Object.prototype.hasOwnProperty.call(data, "date")
      ) {
        let itemDate = firstOrderDate();

        data = {
          ...data,
          date: itemDate
        };
      }

      let cartItem = state.items.find(cartItem => {
        if (cartItem.id !== data.id) {
          return false;
        }

        //in case we're in breakfast order mode we should check we're booking the same item in the same day
        //else we'll trait it as a new item (return false => not found)
        if (isBreakfast() && cartItem.date !== data.date) return false;

        /*if (cartItem.discount !== data.discount) {
          return false;
        }*/

        if (cartItem.mealsize) {
          if (cartItem.mealsize.id !== data.mealsize.id) {
            return false;
          }
        }
        if (cartItem.discount !== data.discount) {
          return false;
        }

        if (
          (Array.isArray(cartItem.garnish) &&
            cartItem.garnish.length !== 0 &&
            cartItem.garnish !== null) ||
          (Array.isArray(data.garnish) &&
            data.garnish.length !== 0 &&
            data.garnish !== null)
        ) {
          if (
            JSON.stringify(cartItem.garnish) !== JSON.stringify(data.garnish)
          ) {
            return false;
          }
        }

        return true;
      });

      if (!cartItem) {
        let itemToBook = {
          id: data.id,
          name: data.name,
          sellPrice: data.sellPrice,
          mealsize: data.mealsize,
          garnish: data.garnish,
          freetext: "",
          amount: data.amount,
          deliverPrice: data.deliverPrice,
          takeAwayPrice: data.takeAwayPrice,
          discount: data.discount,
          itemGroup: data.itemGroup
        };

        if (data.mealsize) {
          // update item to use meal size info instead of the default item
          itemToBook = {
            ...itemToBook,
            name: data.name + " " + data.mealsize.sizeName,
            sellPrice: data.mealsize.sellPrice,
            mealsize: data.mealsize
          };
        }

        // add date attribute in breakfast mode
        if (
          isBreakfast() &&
          Object.prototype.hasOwnProperty.call(data, "date") &&
          moment(data.date, "DD.MM.YYYY").isValid()
        ) {
          itemToBook = {
            ...itemToBook,
            date: data.date
          };
        }

        state.items.push(itemToBook);
      } else {
        cartItem.amount += data.amount;
      }
    },

    removeQTY(state, data) {
      //const item = state.items.find((cartItem) => cartItem.id === data.id);

      let itemIndex = state.items.indexOf(data);

      const item = state.items[itemIndex];

      if (item) {
        if (item.amount > 1) {
          item.amount--;

          this.getters[("Order/itemTotalPrice", data)];
          //this.commit("Order/itemTotalPrice", data);
        }
      }
    },

    addQTY(state, data) {
      //const item = state.items.find((cartItem) => cartItem.id === data.id);

      let itemIndex = state.items.indexOf(data);

      const item = state.items[itemIndex];

      if (item) {
        item.amount++;

        this.getters[("Order/itemTotalPrice", data)];
        //this.commit("Order/itemTotalPrice", data);
      }
    },

    deleteItem(state, data) {
      let itemIndex = state.items.indexOf(data);

      Vue.delete(state.items, itemIndex);
    },

    setItemFreetext(state, data) {
      const item = state.items.find(cartItem => cartItem.id === data.id);

      if (item) {
        item.freetext = data.freetext;
      }
    },
    setItemPrices(state, data) {
      if (data) {
        state.items.forEach(item => {
          if (!item.mealsize) item.sellPrice = item.deliverPrice;

          item.discount = item.itemGroup
            ? item.itemGroup["delivery discount"]
            : 0;
        });
      } else {
        state.items.forEach(item => {
          if (!item.mealsize) item.sellPrice = item.takeAwayPrice;

          item.discount = item.itemGroup
            ? item.itemGroup["takeaway discount"]
            : 0;
        });
      }
    },
    emptyCart(state) {
      state.items = [];
      state.selectedItem = null;
    }
  },

  actions: {
    bookItem({ commit }, data) {
      commit("bookItem", data);
    },
    deleteItem({ commit }, data) {
      commit("deleteItem", data);
    },
    setItemFreetext({ commit }, data) {
      commit("setItemFreetext", data);
    },
    emptyCart({ commit }) {
      commit("emptyCart");
    }
  }
};
