"use strict";

import Vue from "vue";
//import router from "@/router";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import {i18n} from "../../main";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control

  baseURL: process.env.VUE_APP_API_HOST,
  responseType: "json",
 // proxy: process.env.VUE_APP_PROXY_HOST,
  headers: {
    common: {
      "Content-Type": "application/x-www-form-urlencoded",
      //"system-id": process.env.VUE_APP_SYSTEM_ID,
      //"auth-token": process.env.VUE_APP_AUTH_TOKEN,
      //"api-key": process.env.VUE_APP_API_KEY
    } 
  }
};

axios.defaults.baseURL=process.env.VUE_APP_API_HOST;
axios.defaults.responseType="json";
axios.defaults.headers.common['Content-Type']="application/x-www-form-urlencoded";

axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    axios.defaults.headers.common['language'] = i18n.locale
    if(store.getters["auth/isLoggedIn"]){
      axios.defaults.headers.common['Authorization'] = "Bearer "+store.getters["auth/userToken"];
    }
    return config;
  },
  function(error) {
    // Do something with request error
    window.store.commit("Loader/show", false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error

    // if the api request requires authorization header reset auth data  in vuex store
    if (error.response.status === 419 || error.response.status === 401) {

      //set the auth data to null and loggedIn state to false
      store.dispatch("auth/setAuthInfo", null).then(() => {
        axios.defaults.headers.common['Authorization'] = '';
        router.replace({
          name: 'RestaurantMenu'
        });
      })
      return Promise.reject(error.response);
    } else if (error.response.status === 404) {
      return Promise.reject(error.response);
    } else if (error.response.status === 403) {
      return Promise.resolve(error.response);
    } else if (error.response.status === 500) {
      Vue.swal({
        title: i18n.t("delivery.lang_error"),
        icon: "error",
        permanent:true,
        text: i18n.t("delivery.lang_somethingWentWrongPleaseTryAgainLater"),
      });
      return Promise.resolve(error.response);
    }else{
      return Promise.reject(error.response);
    }
  }
);

Plugin.install = function(Vue /*, options*/) {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      }
    },
    $axios: {
      get() {
        return axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
