import { parseCurrentQueryToObject } from "./index";
import moment from "moment";

/**
 * provided start date as url query
 */
export const startDate = parseCurrentQueryToObject().startDate;

/**
 * provided end date as url query
 */
export const endDate = parseCurrentQueryToObject().endDate;

/**
 * provided customer firstname as url query
 */
export const customerFirstName = parseCurrentQueryToObject().firstName;

/**
 * provided customer lastname as url query
 */
export const customerLastName = parseCurrentQueryToObject().lastName;

/**
 * provided customer email as url query
 */
export const customerEmail = parseCurrentQueryToObject().email;

/**
 * provided customer phone as url query
 */
export const customerPhone = parseCurrentQueryToObject().phone;

/**
 * provided customer salutation as url query
 */
export const customerSalutation = parseCurrentQueryToObject().salutation;

/**
 * check whether breakfast mode is enabled or not
 * @return {boolean}
 */
export const isBreakfast = () => {
  return parseCurrentQueryToObject().isBreakfast === true;
};

/**
 * check if the reservation that the current customer trying to order for is expired
 * @return {boolean}
 */
export const isReservationExpired = () => {
  if (
    moment.utc(startDate, "DD.MM.YYYY").isValid() &&
    moment.utc(endDate, "DD.MM.YYYY").isValid()
  )
    return moment().isAfter(moment.utc(endDate, "DD.MM.YYYY"));
  return true;
};

/**
 * in some cases the customer can order breakfast after his reservation is already started so this function checks
 * if the 'startDate' in past it returns the current day as first order date else it returns  'startDate' as first order date
 *
 * @example if startDate=01.05.2022 and currentDate is 03.05.2022 the first order date is 03.05.2022
 * @return {String}
 */
export const firstOrderDate = () => {
  const current = moment();
  if (
    moment.utc(startDate, "DD.MM.YYYY").isValid() &&
    moment.utc(endDate, "DD.MM.YYYY").isValid()
  ) {
    const startUtc = moment.utc(startDate, "DD.MM.YYYY");

    return startUtc.isAfter(current)
      ? startUtc.format("DD.MM.YYYY")
      : current.format("DD.MM.YYYY");
  }
  return current.format("DD.MM.YYYY");
};

/**
 * get all dates between start and end dates of the breakfast as array
 * @return {*[]}
 * @example ['09.05.2022', '10.05.2022', '11.05.2022' ...]
 */
export const breakfastDays = (ignoreFirstDay = false) => {
  if (
    moment.utc(startDate, "DD.MM.YYYY").isValid() &&
    moment.utc(endDate, "DD.MM.YYYY").isValid()
  ) {
    let start = moment.utc(firstOrderDate(), "DD.MM.YYYY");
    let end = moment.utc(endDate, "DD.MM.YYYY");
    let dates = [];

    if (ignoreFirstDay) start.add(1, "days");

    while (start.isSameOrBefore(end)) {
      dates.push(start.format("DD.MM.YYYY"));
      start.add(1, "days");
    }
    return dates;
  }

  return [];
};
