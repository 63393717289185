export const parseCurrentQueryToObject = () => {
  const query = decodeURIComponent(window.location.search);
  if (query) {
    let queryParams = query.replace("?", "").split("&");
    if (Array.isArray(queryParams) && queryParams.length > 0) {
      //get params entries [ [key , value] ... ]
      queryParams = queryParams.map(param => param.split("="));

      //cast booleans
      queryParams = queryParams.map(entry => {
        if (entry[1] === "true" || entry[1] === "false")
          entry[1] = entry[1] === "true";
        return entry;
      });

      return Object.fromEntries(queryParams);
    }
  }
  return {};
};
