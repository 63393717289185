const Loader = {
  namespaced: true,

  state: {
    load: true,
    netError: false,
  },

  getters: {
    loader: (state) => state.load,
    netError: (state) => state.netError,
  },

  mutations: {
    changeStatus: (state, val) => {
      state.load = val;
    },
    toggleNetworkErr: (state, val) => {
      state.netError = val;
    }
  }
};

export default Loader;
