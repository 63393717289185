/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";

const ItemGroups = {
  namespaced: true,

  state: {
    itemGroups: [],
  },

  getters: {
    itemGroups: (state) => state.itemGroups,
  },

  mutations: {
    setItemGroups: (state, val) => {
      state.itemGroups = val;
    },
  },

  actions: {
    async loadItemGroups({ commit }) {
      //commit("Loader/changeStatus", true, { root: true }); //start global loading
      //window.store.commit("Loader/changeStatus", true); //start global loading

      await axios
        .get(ENDPOINTS.DELIVERY.ITEMGROUPS.GET_ALL)
        .then((response) => {
          if (
            response.status >= 200 &&
            response.status < 300 &&
            response.data !== "" &&
            response.data !== null &&
            typeof response.data !== "undefined"
          ) {
            commit("setItemGroups", response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(function() {
          commit("Loader/changeStatus", false, { root: true }); //start global loading
          //window.store.commit("Loader/changeStatus", false); //start global loading
        });
    },
  },
};

export default ItemGroups;
