export const DEFAULT_PAYMENT_METHOD = {
  id: 'localbee_pay_at_checkout_active',
  paymentName: 'Pay At Checkout',
  active: true,
  translations: {
    en: 'Pay At Checkout',
    de: 'Bezahlen beim Checkout',
    fr: 'Payer à la caisse',
    ar: 'الدفع عند الخروج',
  },
};
