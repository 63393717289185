const Auth = {
  namespaced: true,

  state: {
    userToken: null,
    loggedIn: false,
    id: null,
    userName: null,
  },

  getters: {
    isLoggedIn(state){
      return state.loggedIn
    },
    userToken(state){
      return state.userToken
    }
  },

  mutations: {
    setAuthInfo(state, payload) {
      if (payload) {
        state.userToken = payload.userToken;
        state.loggedIn = payload.loggedIn;
        state.id = payload.id;
        state.userName = payload.userName;
        window.localStorage.setItem("userToken",payload.userToken)
      } else {
        state.userToken = null;
        state.loggedIn = false;
        state.id = null;
        state.userName = null;
      }
    },
  },

  actions: {
    setAuthInfo({ commit }, data) {
      commit("setAuthInfo", data);
    },
  },
};

export default Auth;
